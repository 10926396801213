import React from "react";
import { MultiSelect } from "../../../components/menu/multiSelect";

function ActivityFrequencyFilter({
  selected,
  onUpdate,
  onClear,
  fullWidth = false,
  labelPosition = "inset",
  singleSelect = false,
}) {
  // Use the proper data keys: id and name.
  const frequencyOptions = [
    { id: "daily", name: "Daily" },
    { id: "weekly", name: "Weekly" },
    // { id: "monthly", name: "Monthly" },
    // { id: "yearly", name: "Yearly" },
  ];

  return (
    <MultiSelect
      label="Activity frequency"
      options={frequencyOptions}
      onUpdate={onUpdate}
      onClear={onClear}
      selected={selected}
      isLoading={false}
      fullWidth={fullWidth}
      labelPosition={labelPosition}
      singleSelect={singleSelect}
    />
  );
}

export { ActivityFrequencyFilter };
