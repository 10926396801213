import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { FilterPlanel } from "./filterPanel";
import { ActivityReport } from "./activityReport";
import { TableLoadingAnimation } from "../../../components/loading/tableLoading";
import emptyReport from "../../../assets/icons/empty-report.svg";
import emptyArrowRight from "../../../assets/icons/empty-arrow-right.svg";
import { DownloadPdfModal } from "../../../components/modal/downloadPDFModal";

function Reports() {
  const { authContext } = useContext(AuthContext);
  const orgId = authContext.organization.orgId;

  // When the component mounts, no search has been run yet.
  const [hasRunSearch, setHasRunSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filtersState, setFiltersState] = useState(null);
  const [reportData, setReportData] = useState([]);
  const [reportType, setReportType] = useState("activity");
  const [pdfModalOpen, setPdfModalOpen] = useState(false);
  const [isPDFLoading, setIsPDFLoading] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");

  const constructApiURL = (filters, endPoint) => {
    const {
      selectedCustomers,
      selectedLocations,
      selectedActivityTypes,
      selectedUsers,
      dateRange,
      reportType,
    } = filters;

    if (!reportType) {
      console.error("Report type not specified");
      return;
    }

    if (!orgId) {
      console.error("Organization ID not found");
      return;
    }

    if (!selectedCustomers && !selectedLocations && !selectedUsers) {
      console.error("No filters selected");
      return;
    }

    let baseUrl;
    if (endPoint && endPoint.toLowerCase() === "pdf") {
      // Use the PDF endpoint base.
      baseUrl = `${process.env.REACT_APP_API_URL}/v1/pdfs/reports/${reportType}?org_id=${orgId}`;
    } else {
      baseUrl = `${process.env.REACT_APP_API_URL}/v1/reporting/reports/${reportType}?org_id=${orgId}`;
    }

    if (selectedCustomers?.length > 0) {
      const customerIds = selectedCustomers.join(",");
      baseUrl += `&customer_id=${customerIds}`;
    }

    if (selectedLocations?.length > 0) {
      const locationIds = selectedLocations.join(",");
      baseUrl += `&location_ids=${locationIds}`;
    }

    if (selectedActivityTypes?.length > 0) {
      const activityTypeIds = selectedActivityTypes.join(",");
      baseUrl += `&activity_type_ids=${activityTypeIds}`;
    }

    if (selectedUsers?.length > 0) {
      const userIds = selectedUsers.join(",");
      baseUrl += `&user_ids=${userIds}`;
    }

    if (dateRange?.startDate) {
      baseUrl += `&start_date=${dateRange.startDate}`;
    }
    if (dateRange?.endDate) {
      baseUrl += `&end_date=${dateRange.endDate}`;
    }

    console.log("Constructed URL:", baseUrl);
    return baseUrl;
  };

  // Fetches the data from the API (for report data)
  const fetchReportsData = async (url) => {
    if (!orgId) return;
    setIsLoading(true);

    console.log("Fetching data from:", url);

    const fetchPromise = fetch(url)
      .then(async (response) => {
        if (response.ok) {
          const data = await response.json();
          setReportData(data);
        } else {
          console.error("HTTP Error: ", response.statusText);
        }
      })
      .catch((error) => {
        console.error("Fetching error: ", error.message);
      });

    const timerPromise = new Promise((resolve) => setTimeout(resolve, 1200));

    Promise.all([fetchPromise, timerPromise]).then(() => {
      setIsLoading(false);
    });
  };

  // When filters are run, store them and clear out any existing PDF URL.
  const handleRunFilters = (filters) => {
    setHasRunSearch(true);
    setFiltersState(filters);
    setPdfUrl(""); // Clear out any previously generated PDF URL
    const currentApiURL = constructApiURL(filters);
    fetchReportsData(currentApiURL);
  };

  // When the user clicks "Download PDF"
  const handleDownloadPdf = (filters) => {
    if (pdfUrl !== "") {
      setPdfUrl("");
    }
    const currentApiURL = constructApiURL(filters, "pdf");
    fetchReportPDF(currentApiURL);
  };

  // Fetch the PDF URL from the API without altering reportData.
  const fetchReportPDF = async (url) => {
    if (!orgId) return;
    setIsPDFLoading(true);
    setPdfModalOpen(true);

    console.log("Fetching PDF from:", url);

    try {
      const response = await fetch(url);
      if (response.ok) {
        const data = await response.json();
        setPdfUrl(data.pdfUrl);
      } else {
        console.error("HTTP Error: ", response.statusText);
      }
    } catch (error) {
      console.error("Fetching PDF error: ", error.message);
    } finally {
      setIsPDFLoading(false);
    }
  };

  return (
    <div className="flex flex-row flex-1">
      <div className="flex flex-1 flex-col py-6 px-6 overflow-y-auto">
        <div className="flex flex-1 border border-gray-100 rounded-lg">
          {!hasRunSearch ? (
            <div className="flex flex-col m-6 items-center justify-center w-full">
              <img
                src={emptyArrowRight}
                alt="Arrow right"
                className="w-16 h-16"
              />
              <p className="mt-4 text-gray-300 text-center text-md">
                Select the filters & hit run to create your reports
              </p>
            </div>
          ) : isLoading ? (
            <div className="flex items-center justify-center m-6 w-full">
              <TableLoadingAnimation isLoading={isLoading} />
            </div>
          ) : Object.keys(reportData).length > 0 &&
            reportData.summary?.locations > 0 ? (
            <div
              className="flex flex-col w-full overflow-y-auto px-6 py-6"
              style={{ maxHeight: "calc(100vh - 150px)" }}
            >
              <div className="fixed bottom-5 left-1/2 transform -translate-x-1/2 shadow-lg bg-gray-800 text-white rounded-lg text-sm">
                {pdfUrl ? (
                  <a
                    href={pdfUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="px-4 py-2 text-gray-white hover:text-gray-100 transition-all duration-200 ease-in-out block"
                  >
                    Download PDF
                  </a>
                ) : (
                  <button
                    disabled={isPDFLoading}
                    onClick={() => handleDownloadPdf(filtersState)}
                    className="px-4 py-2 text-gray-white hover:text-gray-100 transition-all duration-200 ease-in-out"
                  >
                    Download PDF
                  </button>
                )}
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  {reportData.logo_url ? (
                    <img
                      src={reportData.logo_url}
                      alt={reportData.customer_name}
                      className="h-12 mr-2"
                    />
                  ) : (
                    <span className="text-md font-bold">
                      {reportData.customer_name}
                    </span>
                  )}
                </div>
                <span className="text-sm text-gray-500 text-right">
                  {new Date(reportData.report_date).toLocaleDateString(
                    undefined,
                    {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    }
                  )}
                </span>
              </div>
              <div>
                {reportType === "shift" ? (
                  <div>Shift Report Component</div>
                ) : reportType === "incident" ? (
                  <div>Incident Report Component</div>
                ) : (
                  <ActivityReport reportData={reportData} />
                )}
              </div>
            </div>
          ) : (
            // No data was returned
            <div className="flex flex-col items-center justify-center w-full">
              <img src={emptyReport} alt="No reports" className="w-36 h-36" />
              <p className="mt-2 text-gray-300 text-center text-md">
                No reports for the selected filters
              </p>
            </div>
          )}
        </div>
      </div>

      <div className="bg-gray-25 border-l border-gray-100 flex flex-col relative">
        <FilterPlanel onUpdate={handleRunFilters} />
      </div>
      <DownloadPdfModal
        isOpen={pdfModalOpen}
        isLoading={isPDFLoading}
        url={pdfUrl}
        onClose={() => setPdfModalOpen(false)}
      />
    </div>
  );
}

export { Reports };
