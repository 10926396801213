import React, { useEffect } from "react";
import Lottie from "react-lottie";
import * as animationData from "../../assets/lottie/app-loading.json";

const ModalLoadingAnimation = ({ isLoading, nonAnimated }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  if (nonAnimated) {
    defaultOptions.autoplay = false;
  }

  if (!isLoading) return null;

  return (
    <>
      <div className="flex items-center justify-center">
        <Lottie options={defaultOptions} height={50} width={50} />
      </div>
    </>
  );
};

export { ModalLoadingAnimation };
