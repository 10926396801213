import React from "react";
import { MultiSelect } from "../../../components/menu/multiSelect";

function ReportTypeFilter({
  selected,
  onUpdate,
  onClear,
  fullWidth = false,
  labelPosition = "inset",
  singleSelect = false,
}) {
  // Use the proper data keys: id and name.
  const typeOptions = [
    { id: "activity", name: "Activity report" },
    { id: "shift", name: "Shift report" },
    { id: "incident", name: "Incident report" },
  ];

  return (
    <MultiSelect
      label="Report type"
      options={typeOptions}
      onUpdate={onUpdate}
      onClear={onClear}
      selected={selected}
      isLoading={false}
      fullWidth={fullWidth}
      labelPosition={labelPosition}
      singleSelect={singleSelect}
    />
  );
}

export { ReportTypeFilter };
