import React, { useState, useEffect } from "react";
import { ReportTypeFilter } from "../filters/reportTypeFilter";
import { ActivityFrequencyFilter } from "../filters/activityFrequencyFilter";
import { ReportingCustomerFilter } from "../filters/customerFilter";
import { ReportingLocationFilter } from "../filters/locationFilter";
import { ReportingUserFilter } from "../filters/userFilter";
import { ReportingActivityTypeFilter } from "../filters/activityTypeFilter";
import { PrimaryButton } from "../../../components/button/primary";

import Datepicker from "react-tailwindcss-datepicker";

function FilterPlanel({ onUpdate }) {
  const [reportType, setReportType] = useState(["activity"]);
  const [reportingFrequency, setReportingFrequency] = useState(["daily"]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedActivityTypes, setSelectedActivityTypes] = useState([]);
  const [locationsOptions, setLocationsOptions] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [dateRange, setDateRange] = useState({
    startDate: new Date().toISOString().split("T")[0],
    endDate: new Date().toISOString().split("T")[0],
  });

  /// Handler for "run" click ///
  const handleRunClick = () => {
    const filters = {
      reportType: reportType[0],
      reportingFrequency: reportingFrequency[0],
      selectedCustomers,
      selectedLocations,
      selectedActivityTypes,
      selectedUsers,
      dateRange,
    };
    onUpdate(filters);
  };

  /// Handler for report type change ///
  const handleReportTypeChange = (newReportType) => {
    setReportType(newReportType);
  };

  /// Handle Activity Frequency changes ///
  // This callback now receives an array (from MultiSelect)
  const handleFrequencyChange = (newFrequencyArray) => {
    setReportingFrequency(newFrequencyArray);
    const newFrequency = newFrequencyArray[0] || "daily";
    if (newFrequency === "daily") {
      // Make endDate = startDate
      setDateRange((prev) => ({
        startDate: prev.startDate,
        endDate: prev.startDate,
      }));
    } else if (newFrequency === "weekly") {
      // Make endDate = startDate + 7 days (or 6, as per your preference)
      setDateRange((prev) => {
        const start = new Date(prev.startDate);
        const end = new Date(start);
        end.setDate(end.getDate() + 7);
        return {
          startDate: prev.startDate,
          endDate: end.toISOString().split("T")[0],
        };
      });
    }
  };

  /// Customers dropdown ///
  const handleSelectedCustomers = (option) => {
    setSelectedCustomers(option);
  };

  const clearSelectedCustomers = () => {
    setSelectedCustomers([]);
  };

  /// Locations dropdown ///
  const handleSelectedLocations = (optionId) => {
    setSelectedLocations((currentSelected) => {
      if (currentSelected.includes(optionId)) {
        return currentSelected.filter((item) => item !== optionId);
      } else {
        return [...currentSelected, optionId];
      }
    });
  };

  const clearSelectedLocations = () => {
    setSelectedLocations([]);
  };

  /// Activity Types dropdown ///
  const handleSelectedActivityTypes = (optionId) => {
    setSelectedActivityTypes((currentSelected) => {
      if (currentSelected.includes(optionId)) {
        return currentSelected.filter((item) => item !== optionId);
      } else {
        return [...currentSelected, optionId];
      }
    });
  };

  const clearSelectedActivityTypes = () => {
    setSelectedActivityTypes([]);
  };

  /// Users dropdown ///
  const handleSelectedUsers = (optionId) => {
    setSelectedUsers((currentSelected) => {
      if (currentSelected.includes(optionId)) {
        return currentSelected.filter((item) => item !== optionId);
      } else {
        return [...currentSelected, optionId];
      }
    });
  };

  const clearSelectedUsers = () => {
    setSelectedUsers([]);
  };

  /// Date picker ///
  const handleSelectedDateRange = (newValue) => {
    if (
      reportingFrequency[0] === "weekly" &&
      newValue.startDate &&
      newValue.endDate
    ) {
      // Automatically set end date 6 days after start (or 7, if desired)
      const start = new Date(newValue.startDate);
      const end = new Date(start);
      end.setDate(end.getDate() + 6);
      newValue.endDate = end.toISOString().split("T")[0];
    }
    setDateRange(newValue);
  };

  useEffect(() => {
    // Update the selected locations when selected customers change
    setSelectedLocations((currentSelectedLocations) =>
      currentSelectedLocations.filter((location) =>
        locationsOptions.some((option) => option.id === location)
      )
    );
  }, [selectedCustomers, locationsOptions]);

  return (
    <>
      <div className="flex flex-col h-[calc(100vh-80px)] relative">
        {/* This container represents your sidebar below the header.
      In this example, the sidebar height is the full viewport height minus 100px for the header. */}

        {/* Scrollable filter content */}
        <div className="flex-grow overflow-y-auto pb-20 px-6 py-6">
          <div className="mb-6">
            <ReportTypeFilter
              singleSelect={true}
              selected={reportType}
              onUpdate={handleReportTypeChange}
              fullWidth={true}
              labelPosition="outset"
            />
          </div>
          {reportType[0] === "activity" && (
            <div className="mb-6">
              <ActivityFrequencyFilter
                singleSelect={true}
                selected={reportingFrequency}
                onUpdate={handleFrequencyChange}
                fullWidth={true}
                labelPosition="outset"
              />
            </div>
          )}
          <div className="mb-6">
            <ReportingCustomerFilter
              singleSelect={true}
              selected={selectedCustomers}
              onUpdate={handleSelectedCustomers}
              onClear={clearSelectedCustomers}
              fullWidth={true}
              labelPosition="outset"
            />
          </div>
          <div className="mb-6">
            <ReportingLocationFilter
              selected={selectedLocations}
              onUpdate={handleSelectedLocations}
              onClear={clearSelectedLocations}
              customerIds={selectedCustomers}
              setLocationsOptions={setLocationsOptions}
              fullWidth={true}
              labelPosition="outset"
            />
          </div>
          <div className="mb-6">
            <ReportingUserFilter
              selected={selectedUsers}
              onUpdate={handleSelectedUsers}
              onClear={clearSelectedUsers}
              fullWidth={true}
              labelPosition={"outset"}
            />
          </div>
          <div className="mb-6">
            <ReportingActivityTypeFilter
              selected={selectedActivityTypes}
              onUpdate={handleSelectedActivityTypes}
              onClear={clearSelectedActivityTypes}
              fullWidth={true}
              labelPosition="outset"
            />
          </div>
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-600 mb-1">
              Date
            </label>
            <Datepicker
              key={dateRange.startDate + "-" + dateRange.endDate}
              startFrom={new Date("2024-01-05")}
              showShortcuts={false}
              inputClassName="h-9 w-64 inline-flex rounded-md px-3 -ml-1 mr-1 items-center text-sm leading-none tracking-normal font-normal text-gray-600 placeholder-gray-500 ring-1 ring-gray-100 hover:bg-gray-50 bg-gray-25 border-0 pr-10 focus:outline-none focus:ring-2 focus:ring-gray-100 text-black"
              separator={"to"}
              value={dateRange}
              onChange={handleSelectedDateRange}
              asSingle={reportingFrequency[0] === "daily"}
            />
          </div>
        </div>
      </div>
      <div className="relative">
        {/* Other content in your parent */}
        <div className="absolute bottom-0 left-0 w-full px-4 py-4 border-t border-gray-100 bg-white z-20">
          <PrimaryButton
            label="Run"
            onClick={handleRunClick}
            disabled={selectedCustomers.length === 0}
          />
        </div>
      </div>
    </>
  );
}

export { FilterPlanel };
