import React from "react";
import { StatusIndicator } from "../../../components/statusIndicator";

const ActivityReport = ({ reportData }) => {
  function IncidentSeverity(value) {
    switch (value.toLowerCase()) {
      case "low":
        return <StatusIndicator text="Low" type="success" />;
      case "medium":
        return <StatusIndicator text="Medium" type="warning" />;
      case "high":
        return <StatusIndicator text="High" type="danger" />;
      default:
        return;
    }
  }

  return (
    <>
      <div className="pt-8 pb-6">
        <span className="text-sm block text-gray-800">
          Daily activity report for
        </span>
        <span className="text-xlg text-gray-800">
          {reportData.start_date !== reportData.end_date
            ? `${new Date(reportData.start_date).toLocaleDateString(undefined, {
                year: "numeric",
                month: "long",
                day: "numeric",
              })} - ${new Date(reportData.end_date).toLocaleDateString(
                undefined,
                {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }
              )}`
            : new Date(reportData.start_date).toLocaleDateString(undefined, {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
        </span>
      </div>
      <div>
        <span className="text-gray-700">Summary</span>
      </div>
      <div className="mt-2 grid grid-cols-5 gap-4">
        {reportData.summary &&
          Object.entries(reportData.summary).map(([key, value]) => {
            const label = key.replace(/_/g, " ");
            return (
              <div key={key} className="bg-gray-50 rounded-md p-4">
                <div className="text-xl font-bold text-gray-800">{value}</div>
                <div className="text-xsm text-gray-600 capitalize">{label}</div>
              </div>
            );
          })}
      </div>
      <div className="mt-8">
        {reportData.locations &&
          reportData.locations.map((location) => {
            const sortedActivities = [...location.activities].sort(
              (a, b) =>
                new Date(a.activity_date).getTime() -
                new Date(b.activity_date).getTime()
            );
            return (
              <div key={location.location_id} className="mb-8">
                {/* Location Header */}
                <div className="mb-2">
                  <h3 className="text-gray-700">{location.location_name}</h3>
                  <p className="text-xsm text-gray-700">{location.address}</p>
                </div>
                <div>
                  <table className="min-w-full max-w-full table-fixed">
                    <thead className="text-left text-xxsm text-black uppercase tracking-wider bg-gray-50">
                      <tr>
                        <th className="font-medium px-3 py-3 rounded-tl-lg rounded-bl-lg w-20">
                          Time
                        </th>
                        <th className="font-medium px-3 py-3 w-32">Guard</th>
                        <th className="font-medium px-3 py-3 w-48">
                          Activity type
                        </th>
                        <th className="font-semibold px-3 py-3 rounded-tr-lg rounded-br-lg">
                          Activity details
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-left">
                      {sortedActivities.map((activity) => (
                        <React.Fragment key={activity.activity_log_id}>
                          <tr>
                            <td className="px-3 py-3 whitespace-nowrap text-sm text-gray-500 w-20">
                              {new Date(
                                activity.activity_date
                              ).toLocaleTimeString(undefined, {
                                hour: "2-digit",
                                minute: "2-digit",
                              })}
                            </td>
                            <td className="px-3 py-3 whitespace-nowrap text-sm text-gray-500 w-32">
                              {activity.guard_name}
                            </td>
                            <td className="px-3 py-3 whitespace-nowrap text-sm text-gray-500 w-48">
                              {Array.isArray(activity.activity_type)
                                ? activity.activity_type.map((item, index) => (
                                    <span
                                      key={index}
                                      className={`block mr-1 ${
                                        index === 1 ? "text-xsm" : "text-sm"
                                      }`}
                                    >
                                      {item}
                                    </span>
                                  ))
                                : activity.activity_type}
                            </td>
                            <td className="px-3 py-3 whitespace-nowrap text-sm text-gray-500">
                              {Array.isArray(activity.activity_type) &&
                              activity.activity_type[0].toLowerCase() ===
                                "incident report" ? (
                                <div className="inline-block w-auto ml-2">
                                  {IncidentSeverity(activity.activity_detail)}
                                </div>
                              ) : (
                                activity.activity_detail
                              )}
                            </td>
                            {/* <td className="pl-6 py-1 whitespace-nowrap text-sm text-gray-600 text-right">
                              {activity.media_count}{" "}
                              {activity.media_count === 1 ? "image" : "images"}
                            </td> */}
                          </tr>
                          {activity.media_urls &&
                            activity.media_urls.trim() !== "" && (
                              <tr>
                                <td
                                  colSpan="4"
                                  className="pt-0 px-3 pb-4 border-gray-100 border-b-2"
                                >
                                  <div className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-4 w-full">
                                    {activity.media_urls
                                      .split(",")
                                      .map((url, index) => (
                                        <img
                                          key={index}
                                          src={url}
                                          alt="Activity Media"
                                          className="w-full rounded shadow-sm"
                                        />
                                      ))}
                                  </div>
                                </td>
                              </tr>
                            )}
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export { ActivityReport };
